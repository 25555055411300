import React, { useState } from 'react'
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBIcon,
} from 'mdbreact'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import './navbar.scss'
import { withTheme } from '../Helpers/themes'

const Navbar = props => {
  const [isOpen, setOpen] = useState(false)

  const toggleCollapse = () => {
    setOpen(!isOpen)
  }

  const { allContentfulHeader } = useStaticQuery(graphql`
    query {
      allContentfulHeader {
        edges {
          node {
            headerSocial {
              internal {
                content
              }
            }
            headerTitles {
              internal {
                content
              }
            }
          }
        }
      }
    }
  `)
  const common = allContentfulHeader.edges[0].node
  const headerSocial = JSON.parse(common.headerSocial.internal.content)
  const headerTitles = JSON.parse(common.headerTitles.internal.content)

  return (
    <MDBNavbar style={props.theme.primaryBackgroundColor} dark expand="md">
      <MDBContainer>
        <MDBNavbarToggler
          name="navbar-toggler"
          aria-label="navbar-toggler"
          onClick={toggleCollapse}
        />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav left>
            <div className="d-flex align-items-center">
              <li className="nav-item" role="link">
                <a
                  rel="noopener"
                  className="nav-link"
                  href={headerSocial.linkedin}
                  target="_blank"
                >
                  <MDBIcon fab icon="linkedin" />
                </a>
              </li>
              <li className="nav-item" role="link">
                <a
                  rel="noopener"
                  className="nav-link"
                  href={headerSocial.twitter}
                  target="_blank"
                >
                  <MDBIcon fab icon="twitter" />
                </a>
              </li>
              <li className="nav-item" role="link">
                <a
                  rel="noopener"
                  className="nav-link"
                  href={headerSocial.stackOverflow}
                  target="_blank"
                >
                  <MDBIcon fab icon="stack-overflow" />
                </a>
              </li>
              <li className="nav-item" role="link">
                <a
                  rel="noopener"
                  className="nav-link"
                  href={headerSocial.gitlab}
                  target="_blank"
                >
                  <MDBIcon fab icon="gitlab" />
                </a>
              </li>
              <li className="nav-item" role="link">
                <a
                  rel="noopener"
                  className="nav-link"
                  href={headerSocial.codepen}
                  target="_blank"
                >
                  <MDBIcon fab icon="codepen" />
                </a>
              </li>
            </div>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <li className="nav-item" role="link">
              <Link className="nav-link" to="/">
                {headerTitles.bio}
              </Link>
            </li>
            <li className="nav-item" role="link">
              <Link className="nav-link" to="/favorite-books">
                {headerTitles.fb}
              </Link>
            </li>
            <li className="nav-item" role="link">
              <Link className="nav-link" to="/quotes">
                {headerTitles.quo}
              </Link>
            </li>
            <li className="nav-item" role="link">
              <Link className="nav-link" to="/videos">
                {headerTitles.vid}
              </Link>
            </li>
            <li className="nav-item" role="link" onClick={props.changeTheme}>
              <span className="nav-link">{headerTitles.theme}</span>
            </li>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default withTheme(Navbar)
