import React, { useEffect } from 'react'
import { withTheme } from '../Helpers/themes'
import './backToTop.scss'

const BackToTop = props => {
  const handleScroll = () => {
    let btnTop = document.getElementById('btnTop')
    if (window.scrollY > 500) {
      btnTop.classList.add('show')
    } else {
      btnTop.classList.remove('show')
    }
  }

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <div id="btnTop" className="fixed-action-btn">
      <button
        aria-label="back-to-top"
        className="btn-floating btn-large"
        style={props.theme.primaryBackgroundColor}
        onClick={scrollToTop}
      >
        <i className="fa fa-arrow-up"></i>
      </button>
    </div>
  )
}

export default withTheme(BackToTop)
